/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Accordion, Tab, Tabs } from 'react-bootstrap';

import {
  FormInputChangeRate,
  FormInputCreditScore,
  FormInputCustEmail,
  FormInputDollar,
  FormInputLoanType,
  FormInputLoanTypeHandle,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputUseRatios,
  ValidationModal,
} from '../../../molecules';
import PdfButton from './PdfButton';
import { hBUYERQUAL, SHOW_CONTACT_TAB } from '../../../../api';
import { Help } from '../../../molecules/QuickStart';

import {
  tipLim_piti,
  tipCondo,
  tipCust_name,
  tipEmail,
  tipFiling_status,
  tipFtbuyer,
  tipInput_ltv,
  tipInput_pmi_dollar,
  tipInput_uw_fee,
  tipLend_cred,
  tipM_debts,
  tipOrg_fee,
  tipQ_income,
  tipS_cred,
  tipT_cash,
  tipUse_ratios,
  tipVa_fee_ex,
  tipVa_first,
  UiSettingsProviderContext,
  tipBuyDown,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';

import { BuyerQualCashReport, BuyerQualReport, LimitBuyerQualCashReport, LimitBuyerQualReport } from '../../Reports';
import { BuyerQualGraph } from '../../Graphs';
import ContactOptions from '../../ContactOptions';
import { fetchBuyerQual } from '../../../../api';
import { TBuyerQualResponse } from '../../../../types/api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import { checkAccessFullReport } from '../../../../helpers/checkAccessFullReport';
import FormInputPMICalculation from '../../../molecules/FormInputPMICalculation';
import FormInputFirstVaLoan from '../../../molecules/FormInputFirstVaLoan';
import FormMessageVaLoan from '../../../molecules/FormMessageVaLoan';

const BuyerQual = () => {
  const defaultValues = {
    account_type: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    filing_status: 'm',
    ftbuyer: 'n',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_uw_fee: '',
    inputcred: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    prop_t: '',
    q_income: '',
    s_cred: '',
    sp: '',
    t_cash: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    setValue,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const isAccessFullReport = checkAccessFullReport(currentSettings);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TBuyerQualResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [key, setKey] = useState<string | null>('cash');
  const ctx = useContext(ChristeeConfigCtx);
  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();

    scrollTop();
    setButtonClicked(false);
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));

    console.log('data', data);
    console.log('watch', watch);
    console.log('errors', errors);

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    /**
     * I've changed the params to logged_in_* for all of the forms.  We have
     * an ability to catch the users email from the login, but we also need
     * to be able to update it from the form.
     */
    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const res = await fetchBuyerQual(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );
      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('cash');
      console.log('dats after setDats', dats);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const fireButton = (data: any) => {
    console.log('fireButton->data', data);
    setKey('cash');
    setButtonClicked(true);
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));

    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();

    reset(defaultValues);
    setDats({} as TBuyerQualResponse);
    setLoaded(false);
    setLoading(false);
    setKey('cash');
    scrollTop();
  };

  const watchDoType = watch('doType');

  const show1stTimeBuyer = !!(
    watch('state')?.find((type: { code: string; text: string }) => type.code === 'md') || watchDoType?.find((type: { value: string; text: string }) => type.value === 'CONV')
  );

  return (
    <div className="buyers-choice">
      <div className="container-fluid">
        <div className="row g-2">
          <div className="col-md-3 col-xl-3">
            <h3 className="h2">Buyer PreQual</h3>
            <form className="christy" autoComplete="off">
              <fieldset>
                <legend className="sr-only">Required Fields:</legend>

                <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />

                <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} />

                <FormInputLoanType watch={watchDoType} ref={loanTypeRef} control={control} errors={errors} required={true} />

                {watchDoType && watchDoType?.findIndex((type: any) => type.value === 'VA') !== -1 && <FormMessageVaLoan />}

                <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />

                <FormInputDollar label="Annual Gross Income" tip={tipQ_income} error={errors.q_income} name="q_income" required={true} control={control} />
                <FormInputDollar label="Monthly Debts Payment" tip={tipM_debts} error={errors.m_debts} name="m_debts" required={true} control={control} />

                <FormInputCreditScore error={errors.inputcred} control={control} required={true} />

                <FormInputRadioButtons
                  label="Married"
                  name="filing_status"
                  tip={tipFiling_status}
                  defaultValue="m"
                  required={true}
                  ids={['married', 'single']}
                  values={['m', 's']}
                  control={control}
                  errors={errors}
                />

                {show1stTimeBuyer && (
                  <FormInputRadioButtons
                    label="1st Time Buyer"
                    name="ftbuyer"
                    tip={tipFtbuyer}
                    defaultValue="n"
                    required={true}
                    ids={['first_time_yes', 'first_time_no']}
                    control={control}
                    errors={errors}
                  />
                )}
              </fieldset>

              <Accordion id="optional-inputs">
                <Accordion.Item eventKey="0">
                  <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                  <Accordion.Body>
                    <fieldset>
                      <FormInputDollar label="Enter Cash" tip={tipT_cash} error={errors.t_cash} name="t_cash" control={control} />
                      <FormInputPercent2 label="Seller Credit" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} />
                      <FormInputPercent2 label="Lender Credit" tip={tipLend_cred} error={errors.lend_cred} name="lend_cred" control={control} />
                      <FormInputDollar label="Lender Fees" tip={tipInput_uw_fee} error={errors.input_uw_fee} name="input_uw_fee" control={control} />
                      <FormInputPercent2 label="Loan Origination" tip={tipOrg_fee} error={errors.org_fee} name="org_fee" control={control} max={2} />
                      <FormInputDollar label="Limit Payment" tip={tipLim_piti} error={errors.lim_piti} name="lim_piti" control={control} />
                      <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                      <FormInputDollar label="Condo Fee" tip={tipCondo} error={errors.condo} name="condo" control={control} />
                      <FormInputRadioButtons label="VA Exempt" name="va_fee_ex" tip={tipVa_fee_ex} defaultValue="n" ids={['va_fee_ex_yes', 'va_fee_ex_no']} control={control} errors={errors} />
                      <FormInputFirstVaLoan loanTypes={watch('doType')} setValue={setValue} tip={tipVa_first} control={control} errors={errors} />
                      <FormInputRadioButtons label="Payment Subsidy" name="buyd21" tip={tipBuyDown} defaultValue="n" ids={['buyd21_yes', 'buyd21_no']} control={control} errors={errors} />
                      <FormInputPMICalculation accountType={currentSettings.accountType} tip={tipInput_pmi_dollar} control={control} errors={errors} />
                      <FormInputUseRatios accountType={currentSettings.accountType} tip={tipUse_ratios} control={control} errors={errors} />
                      <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                      <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                      <FormInputCustEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                    </fieldset>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <p className="mt-3">
                <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />

                <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                  Clear
                </button>
              </p>
            </form>
          </div>

          <div className="col-md-9 col-xl-9">
            <div className="form-results">
              {loading ? (
                <img id="processed-image" src="https://www.askchristee.com/images/processed.gif" alt="processed" />
              ) : loaded ? (
                <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                  <Tab eventKey="cash" title="Minimum Cash Results">
                    {loaded ? (
                      <>
                        <br />
                        {isAccessFullReport ? <BuyerQualReport {...dats!} /> : <LimitBuyerQualReport {...dats!} />}
                        <div className="btn btn-generate-pdf" onClick={fireButton}>
                          {(currentSettings.accountType === 'agent' || currentSettings.accountType === 'loan officer') && (
                            <div className="btn btn-generate-pdf" onClick={fireButton}>
                              <button className="btn btn-outline-primary" type="button">
                                Generate PDF
                              </button>
                            </div>
                          )}
                        </div>
                        {buttonClicked ? (
                          <div className="btn btn-download-pdf">
                            <PdfButton dat={dats!} />
                          </div>
                        ) : null}
                        {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                      </>
                    ) : !loading ? (
                      <h3>Complete Form and Press Submit</h3>
                    ) : (
                      <></>
                    )}
                  </Tab>
                  {loaded && (dats?.fha.bCalc || dats?.conventional.bCalc || dats?.va.bCalc || dats?.usda.bCalc) ? (
                    <Tab eventKey="spending" title="Spending Cash">
                      {loaded ? (
                        <>
                          <br />
                          {isAccessFullReport ? <BuyerQualCashReport {...dats!} /> : <LimitBuyerQualCashReport {...dats} />}
                          {(currentSettings.accountType === 'agent' || currentSettings.accountType === 'loan officer') && (
                            <div className="btn btn-generate-pdf" onClick={fireButton}>
                              <button className="btn btn-outline-primary" type="button">
                                Generate PDF
                              </button>
                            </div>
                          )}
                          {buttonClicked ? (
                            <div className="btn btn-download-pdf">
                              <PdfButton dat={dats!} />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <h3>Complete Form and Press Submit - and Have lots of cash on hand!</h3>
                      )}
                    </Tab>
                  ) : (
                    <></>
                  )}
                  <Tab eventKey="spendingGraphs" title="Graphs">
                    <BuyerQualGraph {...dats!} />
                  </Tab>
                  {SHOW_CONTACT_TAB ? (
                    <Tab eventKey="contactOptions" title="Contact Options">
                      <ContactOptions />
                    </Tab>
                  ) : (
                    <></>
                  )}
                </Tabs>
              ) : (
                <>
                  <h4 id="house-title" className="h4">
                    You're seconds away from unlocking your Buyer PreQual data.
                  </h4>
                  <img id="house-image" src="https://www.askchristee.com/images/main.png" alt="home" />
                  <Help sFile={hBUYERQUAL} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerQual;
