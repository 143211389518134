import React, { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import { Button, Modal } from 'react-bootstrap';

import logo from '../../../images/askchristee-logo.svg';
import QuickStartFooter from "../../atoms/QuickStartFooter";

import "./styles.css";

/**
 * RenderHelp is a component that renders the help text for the QuickStart.
 *
 * This organism component should be called by the forms passing in the sFile
 * which is found in the config file.
 *
 * The markdown files are stored externally to the application in an S3 bucket.
 *
 * @param {string} sFile - The file name of the markdown file to be rendered.
 * @returns
 */

type Props = {
  sFile: string;
}

export const Help = ({ sFile }: Props) => {
  const [data, setData] = useState<string>("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(sFile);
      setData(await result.text());
    };
    fetchData();
  }, []);

  return (
    <>
      <Button variant="outline-dark" id="documentation-button" className="float-end mt-5" onClick={handleShow}>
        Help
      </Button>

      <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="amortization-help-title">
        <Modal.Header closeButton>
          <Modal.Title id="amortization-help-title">&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <main id="maincontent" className="interior">
              <div className="container content spacebelow">
                <div className="row">
                  <div className="col-md-12">
                    <div className="contentpadding">
                      <div className="form-wrapper">
                        <div className="quickstart">
                          <ReactMarkdown children={data} />
                          <QuickStartFooter />
                        </div>
                        <div className="container-fluid">
                          <div className="row justify-content-end poweredby">
                            <div className="col-md-12">
                              <p>
                                Powered by &nbsp;
                                <img className="christy-logo" src={logo} width="256" height="47" alt="Christy" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


