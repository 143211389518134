/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useState } from 'react';
import { tipInputcred } from '../../atoms';
import { Modal } from 'react-bootstrap';

type ValidationModalProps = {
  label: string;
  errors: any;
  onClick: React.MouseEventHandler;
};
const ValidationModal = (props: ValidationModalProps) => {
  const [show, setShow] = useState(false);
  let errors = props.errors;
  if (errors && Object.keys(errors).length > 0) {
    console.log('ValidationModal->errors', errors);
  }
  const label = props.label;
  const btnLabel = 'btn-' + label.toLocaleLowerCase();
  console.log('btnLabel', btnLabel);

  const handleClose = () => setShow(false);
  const handleShow = (event: any) => {
    props.onClick ? props.onClick(event) : null;
    setShow(true);
  };

  let percentMessage = ' - Enter a number between 0 and 100';
  let orgFeeMessage = ' - Enter a number between 0 and 2';
  let creditScoreMessage = ' - ' + tipInputcred;
  //  let interestRateMessage = ' - Enter a number between 0 and 100';
  let errorMessages: any = {
    age: { name: 'Age', required: 'is required', min: ' - Enter an age greater than 62 for Reverse Mortgage', max: ' - No one lives past 114' },
    auto_ins: { name: 'Auto Insurance Payment', required: 'is required' },
    cable: { name: 'Cable Payment', required: 'is required' },
    car_pay: { name: 'Car Payment', required: 'is required' },
    c_cards: { name: 'Credit Card Payment', required: 'is required' },
    child: { name: 'Children', required: 'is required', min: ' - Enter a number between 0 and 24', max: ' - Enter a number between 0 and 24' },
    comm: { name: 'Sales Cost', required: 'is required', min: percentMessage, max: percentMessage },
    condo: { name: 'Condo or HOA Fee', required: 'is required' },
    county_select: { name: 'Area', required: 'is required' },
    c_phone: { name: 'Cell Phone Payment', required: 'is required' },
    cred_rate: { name: 'Credit Card Rate', required: 'is required' },
    cred_pay: { name: 'Credit Card Payment', required: 'is required' },
    cred_amt: { name: 'Credit Card Amount', required: 'is required' },
    cust_email: { name: 'Customer Email', required: 'is required' },
    cust_name: { name: 'Customer Name', required: 'is required' },
    cust_phone: { name: 'Customer Phone', required: 'is required' },
    doType: { name: 'Loan Type', required: 'is required' },
    ex_ptax: { name: 'Property Taxes', required: 'is required' },
    filing_status: { name: 'Filing Status', required: 'is required' },
    ftbuyer: { name: 'First Time Buyer', required: 'is required' },
    health_ins: { name: 'Health Insurance Payment', required: 'is required' },
    inc_rent: { name: 'Rent Increase', required: 'is required' },
    inputcred: { name: 'Credit Score', required: 'is required', min: creditScoreMessage, max: creditScoreMessage, maxLength: creditScoreMessage, minLength: creditScoreMessage },
    input_dol_cred: { name: 'Seller Credit', required: 'is required' },
    input_food: { name: 'Food Payment', required: 'is required' },
    input_ltv: { name: 'Loan To Value', required: 'is required' },
    input_ma: { name: 'Loan Amount', required: 'is required' },
    input_pmi$: { name: 'PMI', required: 'is required' },
    input_uw_fee: { name: 'Lender Fee', required: 'is required' },
    input_util: { name: 'Utilities Payment', required: 'is required', inputUtilOrSqFoot: 'or Square Foot is required' },
    int_rate: { name: 'Interest Rate', required: 'is required', min: percentMessage, max: percentMessage },
    int_rate2: { name: 'Future Interest Rate', required: 'is required', min: percentMessage, max: percentMessage },
    invest_return: { name: 'Return on Investment', required: 'is required' },
    land_value: { name: 'Land Value', required: 'is required' },
    lend_cred: { name: 'Lender Credit', required: 'is required', min: percentMessage, max: percentMessage },
    lim_piti: { name: 'Limit Payment', required: 'is required' },
    main_amt: { name: 'Annual Maintenance', required: 'is required' },
    m_debts: { name: 'Monthly Debts', required: 'is required' },
    m_rent: { name: 'Monthly Rent', required: 'is required' },
    org_fee: { name: 'Origination Fee', required: 'is required', min: orgFeeMessage, max: orgFeeMessage },
    other_amt: { name: 'Other Amount', required: 'is required' },
    otherd: { name: 'Other Amount Description', required: 'is required' },
    prop_app: { name: 'Annual Appreciation', required: 'is required' },
    prop_t: { name: 'Property Taxes', required: 'is required' },
    prop_mgt: { name: 'Property Management', required: 'is required' },
    q_income: { name: 'Annual Income', required: 'is required' },
    rent_income: { name: 'Monthly Rent', required: 'is required' },
    report_years: { name: 'Years for Analysis', required: 'is required' },
    sales_cost: { name: 'Sales Cost', required: 'is required' },
    s_cred: { name: 'Seller Credit', required: 'is required', min: percentMessage, max: percentMessage },
    sp: { name: 'Sales Price', required: 'is required' },
    sq_foot: { name: 'Square Foot', required: 'is required' },
    state: { name: 'State', required: 'is required' },
    term: { name: 'Term', required: 'is required' },
    t_cash: { name: 'Cash', required: 'is required' },
    use_ratios: { name: 'Ratios', required: 'is required' },
    va_fee_ex: { name: 'VA Exempt', required: 'is required' },
    vac_allow: { name: 'Vacancy Allowance', required: 'is required' },
  };

  return (
    <>
      <button type="button" className="btn btn-outline-primary me-2" data-cy={btnLabel} onClick={handleShow}>
        {label}
      </button>

      {Object.keys(errors).length > 0 ? (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="validation-modal-title">
          <Modal.Header closeButton>
            <Modal.Title id="validation-modal-title">{Object.keys(errors).length > 0 ? 'Form Errors' : 'Form Inputs are Valid'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {Object.keys(errors).map((key, index) => (
                <li className="mt-1" key={index}>
                  {errorMessages[key]?.name} {errors[key].message ? ' - ' + errors[key].message : errorMessages[key]?.[errors[key].type]}
                </li>
              ))}
            </ul>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default ValidationModal;
