import React from "react";
import logo from "../../../images/askchristee-logo.svg";

export default function PoweredBy() {
  return (
    <p>
      Powered by
      <img
      src={logo}
      className="christy-logo"
      width="312"
      height="94"
      alt="Christy"
      />
    </p>
  );
}
