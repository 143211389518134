import React from "react";

export default function QuickStartFooter() {
  return (
    <div>
      <p>
        Results will be displayed in your browser. Additional detail is
        available in the PDF file. Please review results with a recommended
        Christee Agent and Lender.
      </p>
      <p>
        See the 'Resources' Tab for More Information. Visit 'Video tab for
        Demonstration and Greater Insight. We Hope you Enjoy your Christee
        Experience.
      </p>
    </div>
  );
}
