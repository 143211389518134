import React from "react";

import FormInputRadioButtons from "../FormInputRadioButtons";

type FormInputUseRatiosProps = {
  accountType?: string,
  control: any, errors: any,
  tip?: string,
  disabled?: boolean
}

const FormInputUseRatios = ({ accountType, ...rest }: FormInputUseRatiosProps) => {
  return accountType
    ? <FormInputRadioButtons disabled={accountType === "user"} label="Use Ratios" name="use_ratios" defaultValue={accountType === "user" ? "n" : "y"} ids={['ratios_yes', 'ratios_no']} {...rest} />
    : null;
}

export default FormInputUseRatios;
