import React from "react";

import FormInputRadioButtons from "../FormInputRadioButtons";

type FormInputPMICalculationProps = {
  accountType?: string,
  control: any, errors: any,
  tip?: string,
  disabled?: boolean
}

const FormInputPMICalculation = ({ accountType, ...rest }: FormInputPMICalculationProps) => {
  return accountType
    ? <FormInputRadioButtons
      disabled={accountType === "user"}
      label="PMI Calculation"
      name="input_pmi$"
      defaultValue={accountType === "user" ? "n" : "y"}
      ids={['input_pmi_yes', 'input_pmi_no']}
      {...rest}
    />
    : null;
}

export default FormInputPMICalculation;
