import { VacationForm as Form } from "@ask-christee/ui-response";
import React from "react";
import "../../atoms/Style/form.css";

const Investment = () => {
  return (
    <div>
      <main id="maincontent" className="interior">
        <div className="container content spacebelow">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="contentpadding">
                <div className="form-wrapper">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Investment;
