import React, {useEffect, useState} from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ChristeeConfigCtx } from "../../context";

export type SettingsParamsType = {
    companyId: string,
    sendPdfLink: string,
    email?: string,
    name?: string,
    phone?: string,
    accountType?: string,
    isApproved?: boolean,
    initialCompletion?: number
}

type Props = SettingsParamsType & {
    stage: string,
    recaptchaKey: string,
    children: JSX.Element,
}

const initialSettingsState: SettingsParamsType = {
    companyId: "",
    email: "nil",
    name: "nil",
    phone: "nil",
    sendPdfLink: "",
    accountType: "",
    isApproved: false,
    initialCompletion: 0
}

export const UiSettingsProviderContext = React.createContext({
    currentSettings: initialSettingsState,
    // @ts-ignore
    updateCurrentSettings(newSetting: ParamsType) {}
});

const UiSettingsProvider = ({ recaptchaKey, stage, companyId, sendPdfLink, children }: Props) => {
    const [currentSettings, setCurrentSettings] = useState(initialSettingsState);

    const updateCurrentSettings = (newSettings: SettingsParamsType) => {
        setCurrentSettings(prevState => ({
            ...prevState,
            ...newSettings
        }));
    };

    useEffect(() => {
        setCurrentSettings(prevState => ({
            ...prevState,
            companyId,
            sendPdfLink
        }));
    }, []);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <ToastContainer />
            <ChristeeConfigCtx.Provider value={{
                stage,
                key: null
            }}>
                <UiSettingsProviderContext.Provider value={{
                    currentSettings,
                    updateCurrentSettings
                }}>
                    {children}
                </UiSettingsProviderContext.Provider>
            </ChristeeConfigCtx.Provider>
        </GoogleReCaptchaProvider>
    );
}

export default UiSettingsProvider;
